<template>
  <div>
    <!-- modal -->
    <image-cropper
      v-if="selectedImage"
      :image="selectedImage"
      @cropped="imageCropped"
    />
    <!-- BODY -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <!-- Form -->
      <b-form
        @submit.prevent="handleSubmit(saveData)"
        @reset.prevent="resetForm"
      >

        <validation-provider
          #default="validationContext"
          :name="$t('Üst Kategori')"
          rules="required"
        >
          <b-form-group
            v-if="categoryData"
            :label="$t('Üst Kategori')"
            label-for="parent_category"
          >
            <v-select
              id="parent_category"
              v-model="categoryData.parent_category"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="label"
              :reduce="val => val.value"
              :clearable="false"
              :selectale="categoryData.creator==1"
              :options="caegoriesData"
              :state="getValidationState(validationContext)"
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <validation-provider
          #default="validationContext"
          :name="$t('Kategori Adı')"
          rules="required|min:2"
        >
          <b-form-group
            :label="$t('Kategori Adı')"
            label-for="name"
          >
            <b-form-input
              id="name"
              v-model="categoryData.name"
              autofocus
              autocomplete="off"
              :readonly="categoryData.creator!=1"
              :state="getValidationState(validationContext)"
              trim
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <span>Fiyat Grubu İskontoları</span>
        <b-list-group v-if="categoryData.id<=0">
          <b-list-group-item
            v-for="(item,index) in priceGroupsData"
            :key="index"
            class="d-flex justify-content-between align-items-center"
          >

            <span>{{ item.name }}</span>
            <div>
              <b-input-group
                prepend="Liste Fiyatından %"
                append="İskonto"
              >
                <b-form-input v-model="item.discount_rate" />
              </b-input-group>
            </div>
          </b-list-group-item>

        </b-list-group>
        <b-img

          :src="categoryData.bg_image_url"
          fluid
        />
        <hr>
        <b-form-group
          :label="$t('Kategori Görseli')"
          label-for="image_file_path"
        >
          <b-form-file
            id="image_file_path"
            accept=".jpg, .jpeg"
            :placeholder="$t('Bir dosya seçiniz veya buraya sürükleyiniz')"
            :drop-placeholder="$t('Dosyayı buraya bırakınız...')"
            @change="imageSelected"
          />

        </b-form-group>
        <hr>
        <b-alert
          v-if="categoryData.creator!=1"
          variant="warning"
          show
        >
          <div class="alert-body">
            <span>{{ $t('Bu Veri Sistem Tarafından Otomatik Oluşturulduğu İçin Düzenlenemez ve Silinemez') }}</span>
          </div>
        </b-alert>
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            v-if="categoryData.creator==1"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            type="submit"
          >
            {{ $t('Kaydet') }}
          </b-button>
        </div>

      </b-form>
    </validation-observer>

  </div>
</template>

<script>
import {
  BForm, BFormGroup, BButton, VBModal, BAlert, BFormInput, BFormInvalidFeedback, BImg, BFormFile, BListGroup, BListGroupItem, BInputGroup,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import i18n from '@/libs/i18n'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import imageCropper from '@core/components/image-cropper/ImageCropper.vue'

export default {
  components: {
    BForm,
    BImg,
    BFormGroup,
    BFormFile,
    BListGroup,
    BListGroupItem,
    BInputGroup,
    BFormInput,
    imageCropper,
    BButton,
    BFormInvalidFeedback,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    BAlert,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  model: {
    prop: 'modalShow',
    event: 'update:customer-manager-edit-modal-show',
  },

  props: {
    selectedCategory: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
      blankCategoryData: {
        name: '',
        name_en: '',
        parent_category: 0,
        creator: 1,
        id: 0,
        bg_image_url: '',
      },
      componentMode: 'add',
      categoryData: null,
      showManagerTitleEditor: false,
      managerTitlesData: [],
      managersData: [],
      selectedIndex: -1,
      createdBySystem: false,
      priceGroupsData: [],
    }
  },
  watch: {
  },
  created() {
    if (this.selectedCategory != null) {
      this.componentMode = 'edit'
    } else {
      this.componentMode = 'add'
    }
    if (this.selectedCategory != null) {
      this.blankCategoryData = this.selectedCategory
    }
    this.resetCategoryData()
    this.fetchpriceGroupsData()
  },
  methods: {
    fetchpriceGroupsData() {
      store.dispatch('app-product/fetchPriceGroups', {})
        .then(response => {
          const { data } = response.data
          const priceGroupArr = []
          data.forEach(val => {
            priceGroupArr.push({ id: val.id, name: val.name, discount_rate: null })
          })
          this.priceGroupsData = priceGroupArr
        })
        .catch(() => {
          // alert('Bilgiler getirilirken hata oluştu')
        })
    },
    imageCropped(croppedImage) {
      this.categoryData.bg_image_url = croppedImage
      this.croppedImage = croppedImage
    },
    showSuccessMessage() {
      this.$bvToast.toast(this.$t('İşlem Başarılı'), {
        title: this.$t('İşlem Başarılı'),
        variant: 'success',
        solid: false,
      })
    },
    showErrorMessage() {
      this.$bvToast.toast(this.$t('İşlem Başarısız'), {
        title: this.$t('İşlem Başarısız'),
        variant: 'danger',
        solid: false,
      })
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    imageSelected(event) {
      const file = event.target.files ? event.target.files[0] : null

      this.getBase64(file).then(data => { this.selectedImage = data })
    },
    saveData() {
      if (this.croppedImage != null) {
        this.categoryData.upload_image = this.croppedImage
      }

      if (this.componentMode === 'add') {
        this.priceGroupsData.forEach(val => {
          if (val.discount_rate == null) {
            this.$bvToast.toast(this.$t('EKlenecek kategori için iskonto oranlarını giriniz.'), {
              title: this.$t('DİKKAT'),
              variant: 'danger',
              solid: false,
            })
          }
        })

        this.categoryData.price_group_discounts = this.priceGroupsData
        store.dispatch('app-product/addCategory', this.categoryData)
          // eslint-disable-next-line no-unused-vars
          .then(response => {
            this.resetCategoryData()
            this.$emit('dataChanged')
          })
      } else if (this.componentMode === 'edit') {
        store.dispatch('app-product/updateCategory', this.categoryData)
          .then(() => {
            this.resetCategoryData()
            this.$emit('dataChanged')
          })
      }
    },
    resetModal() {},
    resetCategoryData() {
      this.categoryData = JSON.parse(JSON.stringify(this.blankCategoryData))
      this.selectedIndex = -1
    },
  },
  // eslint-disable-next-line no-unused-vars
  setup(props, { emit }) {
    const croppedImage = ref(null)
    const selectedImage = ref(null)
    const caegoriesData = ref([{ label: i18n.t('Üst Kategori Yok'), value: 0 }])
    store
      .dispatch('app-product/fetchCategories', {
        list_type: 'all',
      })
      .then(response => {
        const { data } = response.data
        const arr = [{ label: i18n.t('Üst Kategori Yok'), value: 0 }]
        data.forEach(val => {
          if (props.selectedCategory != null) {
            if (val.id !== props.selectedCategory.id) {
              arr.push({ label: val.name_with_parents, value: val.id })
            }
          } else {
            arr.push({ label: val.name_with_parents, value: val.id })
          }
        })
        caegoriesData.value = arr
      })
    const onSubmit = () => {
    }
    const resetCategoryData = () => {
      this.categoryData.value = JSON.parse(JSON.stringify(this.blankCategoryData))
    }
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetCategoryData)

    return {
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      caegoriesData,
      croppedImage,
      selectedImage,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
