<template>
  <div>

    <!-- basic modal -->
    <b-modal
      id="cropperModal"
      ref="cropperModal"
      size="lg"
      ok-only
      ok-title="Kırp"
      no-close-on-backdrop
      @ok="handleOk"
    >
      <b-form-select

        v-model="aspectRatio"
        :options="aspectRatioOptions"
        size="sm"
        class="mt-1"
      />
      <hr>
      <vue-cropper
        v-if="image && renderComponent"
        ref="cropper"
        :src="image"
        :view-mode="1"
        :aspect-ratio="aspectRatio"
      />
    </b-modal>

  </div>
</template>

<script>
import { BModal, VBModal, BFormSelect } from 'bootstrap-vue'
// eslint-disable-next-line import/no-extraneous-dependencies
import VueCropper from 'vue-cropperjs'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cropperjs/dist/cropper.css'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BModal,
    VueCropper,
    BFormSelect,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    image: {
      type: String,
      required: true,
    },
    ratio: {
      type: Number,
      default: 16 / 5,
    },
    ratioSelector: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      renderComponent: true,
      aspectRatioOptions: [
        { value: 16 / 2, text: '1600:200' },
        { value: 16 / 3, text: '1600:300' },
        { value: 16 / 4, text: '1600:400' },
        { value: 16 / 5, text: '1600:500' },
        { value: 16 / 6, text: '1600:600' },
        { value: 16 / 7, text: '1600x700' },
        { value: 16 / 8, text: '1600x800' },
        { value: 16 / 9, text: '1600:900' },
        { value: 24 / 4, text: '2400:400' },
        { value: 1 / 1, text: '1500x1500' },
      ],
      aspectRatio: 16 / 5,
    }
  },
  watch: {
    image(val) {
      if (val != null) {
        this.$refs.cropperModal.show()
      }
    },
    aspectRatio() {
      this.forceRerender()
    },
    ratio(val) {
      if (val != null) {
        this.aspectRatio = val
      }
    },
  },
  mounted() {
    if (this.image != null) {
      this.selectedImage = this.image

      this.$refs.cropperModal.show()
    }
  },
  methods: {
    forceRerender() {
      // Remove my-component from the DOM
      this.renderComponent = false

      this.$nextTick(() => {
        // Add the component back in
        this.renderComponent = true
      })
    },
    handleOk() {
      const croppedImage = this.$refs.cropper.getCroppedCanvas().toDataURL()

      this.$emit('cropped', croppedImage)
    },
  },
}
</script>
