import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchPriceGroups(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/price-groups', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addProductsBulk(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('api/products-add-bulk', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateOrdering(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('api/products-update-ordering', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchProducts(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/products', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchProduct(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/products/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addProduct(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/products', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateProduct(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/products/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // categories
    fetchCategories(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/productcategories', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addCategory(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/productcategories', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateCategory(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/productcategories/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteCategory(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/productcategories/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // brands
    fetchBrands(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/product-brands', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addBrand(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/product-brands', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateBrand(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/product-brands/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteBrand(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/product-brands/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // tax types
    addTaxType(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/taxtypes', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateTaxType(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/taxtypes/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteTaxType(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/taxtypes/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // product stores
    fetchStores(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/productstores', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addStore(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/productstores', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateStore(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/productstores/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteStore(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/productstores/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // product images
    addProductImage(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/productimages', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchProductImages(ctx, d) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/productimages', { params: d })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteProductImage(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/productimages/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    changeMainImage(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/productchangemainimage', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // product documents
    addProductDocument(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/productfiles', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchProductDocuments(ctx, d) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/productfiles', { params: d })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteProductDocument(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/productfiles/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
